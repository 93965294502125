import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import { FieldType } from 'Component/Field/Field.config';
import Form from 'Component/Form';
import Loader from 'Component/Loader';
import NewsletterCustomPopup from 'Component/NewsletterCustomPopup';
import PrivacyCheckboxLabel from 'Component/PrivacyCheckboxLabel';
import { ValidationInputType } from 'Util/Validator/Config';

import newsletterImg from './assets/newsletter.svg';

import './NewsletterCustom.style';

/** @namespace Pwa/Component/NewsletterCustom/Component */
export class NewsletterCustomComponent extends PureComponent {
    static propTypes = {
        isFormSubmitted: PropTypes.bool.isRequired,
        submittedMessage: PropTypes.element.isRequired,
        onNewsletterSubscribe: PropTypes.func.isRequired,
    };

    state = {
        isPrivacyChecked: false,
        isMarketingChecked: false,
        privacyError: '',
        marketingError: '',
        isLoading: false,
    };

    renderPrivacyLabel() {
        return (
            <PrivacyCheckboxLabel
              isRequired
              isNlCheckbox
              label={ __('Dichiaro di aver preso visione dell’') }
              link={ __('Informativa privacy') }
            />
        );
    }

    renderMarketingLabel() {
        return (
            <PrivacyCheckboxLabel
              isRequired
              isNlCheckbox
              label={ __(
                  'Presto il consenso alle finalità di marketing diretto attraverso forme “tradizionali” e “automatizzate” di contatto (art. 130 c. 1 e 2 del D.lgs. 196/03 e s.m.i), come indicato al punto 4 del paragrafo “Finalità del trattamento e base giuridica” dell’'
              ) }
              link={ __('Informativa privacy') }
            />
        );
    }

    handleCheckboxChange = (event) => {
        const { name, checked } = event.target;

        if (name === 'privacy_check') {
            this.setState({
                isPrivacyChecked: checked,
                privacyError: checked ? '' : __('Required'),
            });
        } else if (name === 'marketing_check') {
            this.setState({
                isMarketingChecked: checked,
                marketingError: checked ? '' : __('Required'),
            });
        }
    };

    handleSubmit = async (form, fields) => {
        const { isPrivacyChecked, isMarketingChecked } = this.state;
        const { onNewsletterSubscribe } = this.props;

        if (!isPrivacyChecked || !isMarketingChecked) {
            this.setState({
                privacyError: !isPrivacyChecked ? __('Required') : '',
                marketingError: !isMarketingChecked ? __('Required') : '',
            });

            return;
        }

        try {
            this.setState({ isLoading: true });
            await onNewsletterSubscribe(form, fields);
            this.setState({ isLoading: false });
        } catch (error) {
            this.setState({ isLoading: false });
        }
    };

    render() {
        const { isFormSubmitted, submittedMessage } = this.props;
        const { privacyError, marketingError, isLoading } = this.state;

        let content = (
            <div
              block="NewsletterCustom"
              elem="Content"
              className="neoNewsletter"
            >
                <div>
                    <div block="title">
                        { __('Registrati alla nostra Newsletter') }
                    </div>
                    <div block="content">
                        <p block="info">
                            { __(
                                'Sconti, promozioni, novità e ispirazione: Abbiamo un mondo di cose da dirti'
                            ) }
                        </p>
                        <Form
                          mix={ { block: 'NewsletterCustom', elem: 'Form' } }
                          key="confirm-account"
                          onSubmit={ this.handleSubmit }
                          onError={ this.handleCheckboxChange }
                        >
                            <div>
                                <label
                                  block="NewsletterCheckbox"
                                  elem="Row"
                                  onChange={ this.handleCheckboxChange }
                                >
                                    <div block="NewsletterCheckbox" elem="Box">
                                        <Field
                                          attr={ {
                                              id: 'privacy_check',
                                              name: 'privacy_check',
                                          } }
                                          mix={ {
                                              block: 'NewsletterCustom',
                                              elem: 'Checkbox',
                                              mods: {
                                                  FooterNewsletter: true,
                                              },
                                          } }
                                          validateOn={ ['onChange'] }
                                          validationRule={ {
                                              isRequired: true,
                                          } }
                                          type={ FieldType.CHECKBOX }
                                          events={ {
                                              onChange:
                                                    this.handleCheckboxChange,
                                          } }
                                        />
                                        { this.renderPrivacyLabel() }
                                    </div>
                                    { privacyError && (
                                        <p block="Field" elem="Message">
                                            { privacyError }
                                        </p>
                                    ) }
                                </label>
                                <label
                                  block="NewsletterCheckbox"
                                  elem="Row"
                                  onChange={ this.handleCheckboxChange }
                                >
                                    <div block="NewsletterCheckbox" elem="Box">
                                        <Field
                                          attr={ {
                                              type: FieldType.CHECKBOX,
                                              id: 'marketing_check',
                                              name: 'marketing_check',
                                          } }
                                          mix={ {
                                              block: 'NewsletterCustom',
                                              elem: 'Checkbox',
                                              mods: {
                                                  FooterNewsletter: true,
                                              },
                                          } }
                                          validateOn={ ['onChange'] }
                                          validationRule={ {
                                              isRequired: true,
                                          } }
                                          type={ FieldType.CHECKBOX }
                                            // label={this.renderMarketingLabel()}
                                          events={ {
                                              onChange:
                                                    this.handleCheckboxChange,
                                          } }
                                        />
                                        { this.renderMarketingLabel() }
                                    </div>
                                    { marketingError && (
                                        <p block="Field" elem="Message">
                                            { marketingError }
                                        </p>
                                    ) }
                                </label>
                            </div>

                            <div block="NewsletterCustom" elem="Group">
                                <Field
                                  attr={ {
                                      id: 'newsletter_email',
                                      name: 'newsletter_email',
                                      placeholder: __(
                                          'Enter your email address'
                                      ),
                                      'aria-label': __('Email address'),
                                  } }
                                  mix={ {
                                      block: 'NewsletterCustom',
                                      elem: 'Input',
                                  } }
                                  type={ FieldType.EMAIL }
                                  validateOn={ ['onChange'] }
                                  validationRule={ {
                                      isRequired: true,
                                      inputType: ValidationInputType.EMAIL,
                                  } }
                                />
                                <button
                                  type="submit"
                                  mix={ {
                                      block: 'NewsletterCustom',
                                      elem: 'Button',
                                  } }
                                >
                                    { __('Subscribe') }
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
                <img src={ newsletterImg } alt="newsletter" />
            </div>
        );

        if (isFormSubmitted && submittedMessage) {
            content = submittedMessage;
        }

        return (
            <div block="NewsletterCustom">
                <Loader isLoading={ isLoading } />
                <NewsletterCustomPopup />
                { content }
            </div>
        );
    }
}

export default NewsletterCustomComponent;
