import axios from 'axios';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { NEWSLETTER_CUSTOM_POPUP } from 'Component/NewsletterCustomPopup/NewsletterCustomPopup.config';
import { showPopup } from 'Store/Popup/Popup.action';
import transformToNameValuePair from 'Util/Form/Transform';

import NewsletterCustom from './NewsletterCustom.component';
import { SUBSCRIBE_URL } from './NewsletterCustom.config';

export const GTMDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/GTM/GTM.dispatcher'
);

/** @namespace Pwa/Component/NewsletterCustom/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({});

/** @namespace Pwa/Component/NewsletterCustom/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showPopup: (payload) => dispatch(showPopup(NEWSLETTER_CUSTOM_POPUP, payload)),
    pushNewsletterFooter: () =>
        GTMDispatcher.then(({ default: dispatcher }) =>
            dispatcher.pushNewsletterFooter(dispatch)),
});

/** @namespace Pwa/Component/NewsletterCustom/Container */
export class NewsletterCustomContainer extends PureComponent {
    static propTypes = {
        showPopup: PropTypes.func.isRequired,
        pushNewsletterFooter: PropTypes.func.isRequired,
        submittedMessage: PropTypes.element,
    };

    static defaultProps = {
        submittedMessage: null,
    };

    state = { isLoading: false, isFormSubmitted: false };

    containerFunctions = {
        onNewsletterSubscribe: this.onNewsletterSubscribe.bind(this),
    };

    async onNewsletterSubscribe(form, fields) {
        const { showPopup, pushNewsletterFooter } = this.props;
        const { newsletter_email } = transformToNameValuePair(fields);

        try {
            this.setState({ isLoading: true });
            const url = new URL(window.location.href);
            let campaignId = url.searchParams.get('id');

            if (
                window.location.pathname === '/trasporto-e-montaggio'
        && !campaignId
            ) {
                campaignId = '7013X000001bk5bQAA';
                window?.dataLayer.push({ event: 'Form-promo coupon 01' });
            }

            if (
                window.location.pathname === '/promozione-camerette-coupon'
        && !campaignId
            ) {
                campaignId = '7013X000001blpVQAQ';
                window?.dataLayer.push({ event: 'form-promozione-camerette-coupon' });
            }

            if (
                window.location.pathname === '/promozione-camere-coupon'
        && !campaignId
            ) {
                campaignId = '7013X000001blw2QAA';
                window?.dataLayer.push({ event: 'form-promozione-camere-coupon' });
            }

            if (window.location.pathname === '/lp-mcday2022' && !campaignId) {
                campaignId = '7013X000001bnFkQAI';
                window?.dataLayer.push({ event: 'form-mc-day-2022' });
            }

            if (
                window.location.pathname === '/super-saldi-arredamento'
        && !campaignId
            ) {
                campaignId = '7013X000001kS5AQAU';
                window?.dataLayer.push({ event: 'super-saldi-arredamento-2023' });
            }
            const payload = {
                email: newsletter_email,
                campaignId,
            };

            await axios.post(SUBSCRIBE_URL, payload);
            // EVENTO GA4
            window?.dataLayer?.push({
                event: 'newsletter_signup',
                form_newsletter: 'true',
            });
            pushNewsletterFooter();
            this.setState({ isLoading: false, isFormSubmitted: true });

            showPopup();
        } catch (error) {
            this.setState({ isLoading: false, isFormSubmitted: true });
        }
    }

    containerProps = () => {
    // isDisabled: this._getIsDisabled()
    };

    render() {
        const { isLoading, isFormSubmitted } = this.state;
        const { submittedMessage } = this.props;

        if (window.location.pathname.toLowerCase() !== '/mcday') {
            return (
        <NewsletterCustom
          isLoading={ isLoading }
          isFormSubmitted={ isFormSubmitted }
          submittedMessage={ submittedMessage }
          { ...this.containerFunctions }
          { ...this.containerProps() }
        />
            );
        }

        return null;
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewsletterCustomContainer);
