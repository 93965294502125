import { connect } from 'react-redux';

import PrivacyCheckboxLabel from './PrivacyCheckboxLabel.component';

/** @namespace Pwa/Component/PrivacyCheckboxLabel/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    storeCode: state.ConfigReducer.code,
});

/** @namespace Pwa/Component/PrivacyCheckboxLabel/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    // addProduct: options => CartDispatcher.addProductToCart(dispatch, options)
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyCheckboxLabel);
